<template>
	<div class='sign-up'>
		<div class="app-container">
			<h1 class="heading-2 green--text">{{$t("routes.sign-up")}}</h1>

			<div class="divider"></div>

			<form-sign-up-en
				:success='success'
				@change-success='signUpSuccess($event)'
				v-if='$i18n.locale === "en"'
			/>

			<form-sign-up-default
				:success='success'
				@change-success='signUpSuccess($event)'
				v-else
			/>
		</div>

		<v-dialog :value='success' :showCloseCross='false' v-if='success'>
			<div class="app-done-modal app-done-modal--registration">
				<h3 class="heading-3">{{$t('auth.sign_up.dialog_success.title')}}</h3>
				<span>{{$t('auth.sign_up.dialog_success.description')}}</span>
				<v-button class='green white--text' small :to="$i18nRoute({ name: 'sign-in' })">
					{{$t('auth.sign_up.dialog_success.button')}}
				</v-button>
			</div>
		</v-dialog>
	</div>
</template>

<script>
	import FormSignUpDefault from '@/views/auth/components/form-sign-up-default.vue';
	import FormSignUpEn from '@/views/auth/components/form-sign-up-en.vue';

	export default {
		data: () => ({
			success: false
		}),
		components: {
			FormSignUpDefault,
			FormSignUpEn
		},
		methods: {
			signUpSuccess($event) {
				this.success = $event;
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.sign-up {
		.app-container {
			max-width: 1435px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 0 40px;
			}
		}
		&-header {
			@include maxw( $grid-breakpoints-sm - 1 ) {
				.col-3 {
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}
		.heading-2 {
			padding-top: 40px;
			padding-bottom: 20px;
		}
		&-content {
			margin-top: 30px;
			margin-bottom: 100px;
			.mx-datepicker-small {
				@include maxw( $grid-breakpoints-sm - 1 ) {
					.mx-datepicker-main {
						right: 0;
						left: auto !important;
						&:before {
							top: -7px;
							right: 10px;
							left: auto;
						}
					}
				}
			}
		}
		&-title {
			font-size: 22px;
			font-weight: 700;
			margin-bottom: 23px;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 32px * .8;
				margin-top: 6px;
			}
			@include minw( $grid-breakpoints-md ) {
				font-size: 32px;
			}
		}
		&-sub-title {
			font-weight: 700;
			font-size: 16px;
			margin-bottom: 25px;
			@include minw( $grid-breakpoints-xs ) {
				font-size: 20px;
				margin-bottom: 15px;
			}
		}
		.app-checkbox {
			margin-bottom: 23px;
		}
		&-buttons {
			@include maxw( $grid-breakpoints-xs - 1 ) {
				flex-direction: column-reverse;
				.app-btn {
					width: 100%;
					margin-bottom: 15px;
				}
			}
		}
	}
</style>
