<template>
	<div class="sign-up-content">
		<div class="row sign-up-header">
			<div class="col">
				<p class='sign-up-title'>{{$t("auth.sign_up.title")}}</p>
			</div>
		</div>

		<ValidationObserver v-slot="{ invalid }">
			<form @submit.prevent='onSubmit'>
				<div v-for='(item, i) in items' :key='i'>
					<div class="row sign-up-row-custom">
						<div
							:class="field.col ? `col-${field.col}`: 'col'"
							v-for='(field, i) in item.fields'
							:key='i'
						>
							<ValidationProvider
								:name='field.name === "iban" ? `${$t("auth.sign_up.iban")} / ${$t("auth.sign_up.account_number")}` : $t(`auth.sign_up.${field.name}`)'
								:rules="field.rules ? field.rules : { required: false }"
								v-slot='{errors, classes}'
							>
								<v-text-field
									:label='field.name === "iban" ? `${$t("auth.sign_up.iban")} / ${$t("auth.sign_up.account_number")}` : $t(`auth.sign_up.${field.name}`)'
									:placeholder='field.placeholder ? field.placeholder : $t(`auth.sign_up.placeholder.${field.name}`)'
									:name='field.name'
									:class='field.rules ? classes : ""'
									v-model='form[field.value]'
									:errorMessage='errors[0]'
									:required='field.rules ? true : false'
									:mask='field.mask ? field.mask : false'
								/>
							</ValidationProvider>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col">
						<v-checkbox
							name='terms_check'
							:label='$t("auth.sign_up.checkbox")'
							v-model='form.agreement'
							required
						/>
					</div>
				</div>

				<div class="row sign-up-buttons">
					<div class="col-auto">
						<v-button color='green' outline :to="$i18nRoute({name: 'sign-in'})">{{$t('auth.sign_up.cancel')}}</v-button>
					</div>
					<div class="col-auto">
						<v-button color='green white--text' :disabled="invalid || !form.agreement" @click='onSubmit' :loading='loading'>{{$t('auth.sign_up.next')}}</v-button>
					</div>
				</div>
			</form>
		</ValidationObserver>
	</div>
</template>

<script>
	import { registration } from '@/services/request';

	export default {
		data: () => ({
			loading: false,
			form: {
				agreement: false
			},
			placeholder: {
				phone_number: '+380 50 000 54 54',
				email: 'company@banana.com.ua',
				iban: 'UA00 0000 0000 0000 0000 0000 0000',
				swift: 'XXX - XX - XX - XXX'
			}
		}),
		computed: {
			items() {
				return [
					{ fields: [
						{ col: '3', name: 'name_company', value: 'companyName', rules: 'required' },
						{ col: '6', name: 'address', value: 'addressOffice', rules: 'required' },
						{ col: '3', name: 'phone_number', value: 'telMobileReg', rules: 'required|numeric|length:12', placeholder: this.placeholder.phone_number, mask: '+### ## ### ## ##' },
						{ col: '3', name: 'email', value: 'emailExtra', rules: 'required|email', placeholder: this.placeholder.email },
						{ col: '3', name: 'account_holder', value: 'accountOwner', rules: 'required' },
						{ col: '6', name: 'iban', value: 'iban', rules: 'required|length:29', placeholder: this.placeholder.iban, mask: 'UA## #### #### #### #### #### #### #' },
						{ col: '3', name: 'name_bank', value: 'bank', rules: 'required' },
						{ col: '6', name: 'bank_address', value: 'bankAddress', rules: 'required' },
						{ col: '3', name: 'swift', value: 'swift', rules: 'required|length:10', placeholder: this.placeholder.swift, mask: '### - ## - ## - ###' }
					]}
				]
			}
		},
		methods: {
			onSubmit() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;

				registration(this.form).then(() => {
					this.$emit("change-success", true)
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {errors: data.errors})
				}).finally(() => this.loading = false)
			},
			closeDialog() {
				this.$emit('close-dialog');
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";

	.sign-up-row-custom {
		.col-6 {
			@media only screen and (min-width: $grid-breakpoints-xs) and (max-width: $grid-breakpoints-sm - 1) {
				flex: 0 0 calc(100% / 2);
				max-width: calc(100% / 2);
			}
		}
	}
</style>
